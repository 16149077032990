// Generated by Framer (2c4b293)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, ResolveLinks, RichText, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import HnappurAllar from "./KLLYCtHHd";
import * as localizedValues from "./PsuR5N_5G-0.js";
const PhosphorFonts = getFonts(Phosphor);
const HnappurAllarFonts = getFonts(HnappurAllar);

const cycleOrder = ["vcXq3ENxf"];

const serializationHash = "framer-kpB0j"

const variantClassNames = {vcXq3ENxf: "framer-v-1u5jnhk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vcXq3ENxf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1u5jnhk", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vcXq3ENxf"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1ymsbse"} layoutDependency={layoutDependency} layoutId={"lGXFL2cIX"}><ComponentViewportProvider ><motion.div className={"framer-11m5wpv-container"} layoutDependency={layoutDependency} layoutId={"GnnZOv__2-container"}><Phosphor color={"var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowBendLeftDown"} id={"GnnZOv__2"} layoutId={"GnnZOv__2"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={getLocalizedValue("v0", activeLocale) ?? <React.Fragment><motion.h2 style={{"--font-selector": "R0Y7R2FiYXJpdG8tNjAw", "--framer-font-family": "\"Gabarito\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.01em", "--framer-text-alignment": "right", "--framer-text-color": "var(--extracted-1of0zx5, rgb(51, 51, 51))"}}>Þetta er Snjallborði</motion.h2></React.Fragment>} className={"framer-14ngazv"} data-framer-name={"Nýjustu fréttir"} fonts={["GF;Gabarito-600"]} layoutDependency={layoutDependency} layoutId={"u4DiHv2V5"} style={{"--extracted-1of0zx5": "rgb(51, 51, 51)"}} verticalAlignment={"center"} withExternalLayout/></motion.div><ResolveLinks links={[{href: {webPageId: "mKIEmEBgy"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider height={32} y={((componentViewport?.y || 0) + 0 + (((componentViewport?.height || 32) - 0) - 32))}><motion.div className={"framer-kaq8hz-container"} layoutDependency={layoutDependency} layoutId={"LAF3GDQqJ-container"}><HnappurAllar dL_3WY8g1={getLocalizedValue("v1", activeLocale) ?? "Nánar"} height={"100%"} id={"LAF3GDQqJ"} layoutId={"LAF3GDQqJ"} Nf2kMP4WU={resolvedLinks[0]} snbysKTAD={{borderColor: "var(--token-4b580f56-82e7-479a-ad6b-fb237c77425e, rgb(230, 230, 230)) /* {\"name\":\"Border\"} */", borderStyle: "solid", borderWidth: 0}} TkEIsv00C={"rgb(61, 61, 61)"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kpB0j.framer-xj1ikj, .framer-kpB0j .framer-xj1ikj { display: block; }", ".framer-kpB0j.framer-1u5jnhk { align-content: flex-end; align-items: flex-end; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 18px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 980px; }", ".framer-kpB0j .framer-1ymsbse { align-content: flex-end; align-items: flex-end; align-self: stretch; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: auto; justify-content: flex-end; overflow: hidden; padding: 0px 0px 0px 30px; position: relative; width: 1px; }", ".framer-kpB0j .framer-11m5wpv-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 21px); position: relative; width: 18px; }", ".framer-kpB0j .framer-14ngazv { flex: none; height: 100%; position: relative; white-space: pre; width: auto; }", ".framer-kpB0j .framer-kaq8hz-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kpB0j.framer-1u5jnhk, .framer-kpB0j .framer-1ymsbse { gap: 0px; } .framer-kpB0j.framer-1u5jnhk > * { margin: 0px; margin-left: calc(18px / 2); margin-right: calc(18px / 2); } .framer-kpB0j.framer-1u5jnhk > :first-child, .framer-kpB0j .framer-1ymsbse > :first-child { margin-left: 0px; } .framer-kpB0j.framer-1u5jnhk > :last-child, .framer-kpB0j .framer-1ymsbse > :last-child { margin-right: 0px; } .framer-kpB0j .framer-1ymsbse > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 980
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPsuR5N_5G: React.ComponentType<Props> = withCSS(Component, css, "framer-kpB0j") as typeof Component;
export default FramerPsuR5N_5G;

FramerPsuR5N_5G.displayName = "1018-TopInfoCTA";

FramerPsuR5N_5G.defaultProps = {height: 32, width: 980};

addFonts(FramerPsuR5N_5G, [{explicitInter: true, fonts: [{family: "Gabarito", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/gabarito/v7/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2PcW0EwItq6bFIg.woff2", weight: "600"}]}, ...PhosphorFonts, ...HnappurAllarFonts], {supportsExplicitInterCodegen: true})